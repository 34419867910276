module.exports = {
	APIServer: process.env.API_SERVER,
	cookieConsentName: "weblica-analytics-consent",
	vatPercent: 25,
	altConversionRate: 7.5345,
	defaultLanguage: "en",
	multiLanguage: {
		active: false,
		alwaysGenerate: false, // if set, system will generate all language pages (further options define behavior)
		inactiveVariant: "force_default_language", // force_default_language || unsupported_language_page - type of generated page
		dummyRedirectTimeOut: 3, // if set, automatic redirect will be done after given number of seconds
	},
	languages: {
		hr: {
			available: true, // available in language selector for front-end
			option: "HR",
			name: "Hrvatski",
			flagIcon: "flag-croatia.png",
			dateFormat: "DD[.] MMMM YYYY.", // [.] = naziv mjeseca u genitivu (npr. studenoga)
			dateFormatAlt: "DD. MMMM YYYY.", // . = naziv mjeseca u nominativu (npr. studeni)
			dateFormatAltNoYear: "DD. MMMM", // . = naziv mjeseca u nominativu (npr. studeni)
			dateFormatISO: "YYYY-MM-DD",
			dateFormatNumbers: "DD.MM.YYYY.",
			timeFormat: "HH:mm",
			at: "u",
			locale: "hr-HR",
			htmlMetaLanguage: "hr-HR",
			currency: {
				code: "EUR",
				symbol: "€",
			},
			currencyAlt: {
				code: "HRK",
				symbol: "kn",
			},
			ordinal: (n) => {
				const r = `[${n}.]`
				return r
			},
		},
		en: {
			available: true,
			option: "EN",
			name: "English",
			flagIcon: "flag-usa.png",
			dateFormat: "MMMM Do, YYYY",
			dateFormatNoYear: "MMMM Do",
			dateFormatAlt: "MMM Do, YYYY",
			dateFormatAltNoYear: "MMM Do",
			dateFormatISO: "YYYY-MM-DD",
			dateFormatNumbers: "MM/DD/YYYY",
			timeFormat: "HH:mm",
			at: "at",
			locale: "en-US",
			htmlMetaLanguage: "en-US",
			currency: {
				code: "EUR",
				symbol: "€",
			},
			currencyAlt: {
				code: "HRK",
				symbol: "kn",
			},
			ordinal: (n) => {
				const s = ["th", "st", "nd", "rd"]
				const v = n % 100
				const r = `[${n}${s[(v - 20) % 10] || s[v] || s[0]}]`
				return r
			},
		},
	},
	shirtSizes: ["S", "M", "L", "XL", "2XL", "3XL", "4XL", "5XL", "6XL"],
	socialLinks: {
		facebook: "https://www.facebook.com/weblicahr/",
		instagram: "https://www.instagram.com/weblica.hr/",
		twitter: "https://twitter.com/weblicahr",
		linkedin: "https://www.linkedin.com/company/weblica",
		youtube: "https://www.youtube.com/@weblicahr3028",
	},
	devYears: [
		// 2023, // dev
		2024,
		2025,
		// 2026 // dev
	],
	devFlags: {
		buildNonVisibleEvents: false,
		showTestButtons: false, // if true, shows test buttons on all Hero sections
		showTestColors: false, // if true, shows test color boxes on all Hero sections
		forceFullScheduleAndSpeakers: false, // ignores property "visible" in session and speaker entries and shows all (also turn off preview modes)
		rootReplacementComponent: "./src/pages/weblica/index.js", // if null or omitted, root (/) shows initial event selection - change requires rebuild
	},
	uiFlags: {
		showSpritesOnSectionTitles: false,
		useConfigFormat: true, // override predefined locale formats
		noDataBehavior: "TBD", // TBD - render TBD message; SKIP - do not render
		collapsibleSections: true, // if true, most sections will be collapsed on mobile devices (small screens)
		themes: {
			active: false, // if true, theme selector is visible
			default: "dark", // dark || light || browser
		},
		menu: {
			hashedLinks: true // if true, all menu options are lowercased and # is prefixed
		},
		header: {
			showLanguageSelector: true,
			showEventLogo: false,
			showEventTitle: true,
			eventColorsOnEventBar: false, // if true, eventColorsOnEventButtons needs to be false
			eventColorsOnEventButtons: true, // if true, eventColorsOnEventBar needs to be false
			renderEventsOnEventBar: true,
			renderEventButtonsOnMenuBar: true,
			venueLinkType: "section", // map | section
		},
		hero: {
			showLogo: true,
			showTitle: false,
			showSubTitle: true,
			showTags: false,
			showSurveyButtonOnFinished: true,
			showSurveyButtonForLastYearOnFinished: false,
			showWeekDay: false,
			showStartTime: false,
			showBreakingNews: true,
			showCountDownTimer: false
		},
		news: {
			skipUnreachedDates: true, // if true, all news that are in the future (prepared), will be skipped from displaying
		},
		speakers: {
			allowPlaceholders: false, // if true, and item is marked as placeholder, it will be shown as dummy image and TBD as personal name (item will be display even if is visible=false and placeholder=true)
			markKeynoteSpeaker: true, // if true, a gold star will be displayed next to speakers' name
			keynoteSpeakerFirst: false,
			showHistorySpeakers: true, // if true, shows speakers from previous event, but only until we have at least one new entry - just a placeholder
			maxHistorySpeakersShown: 15, // if null, all history speakers are shown
			historySpeakerItemLinks: "speaker" // null - no links, "history" - jumps to the history year of the item, "speaker" - jumps to linkedin of the speaker (if available)
		},
		schedule: {
			allowPlaceholders: false, // if true, and item is marked as placeholder, it will be shown with TBD instead of title but with real duration (item will be display even if is visible=false and placeholder=true)
			showTrackSelector: false,
			showExtraDetails: [
				"language-text",
				// "language-flag",
				"level-text",
				// "level-indicator",
				"code-text",
				// "code-indicator",
				"tags"
			],
			showStaticTags: true, // if true, tags are visible on sessions all the time (no need to expand the item)
			showVenueMarker: true, // if true, and there are more than one venues, item will be marked with venue name (if item has venue identifier)
			showTypeMarker: true, // TODO (LOW) - if true, session is marked with type text or even better, simple icon - talk or workshop
			useStars: true, // if true, stars will be used to mark wanted sessions
			useNotifications: false, // if true, notification system (sound or vibrate) will be available to the user
			showFullPageButton: false, // if true, a button for full page schedule will be rendered
			useSpeakerInfo: "popup", // null | link | popup
			useTrackMarkerColors: "badge", // [blank] | badge | text
			showUnifiedTrackTime: false,
			showFromToOnBreaks: false, // show from-to time on breaks
			markKeynote: true,
			showHistorySessions: true, // if true, shows sessions from previous event, but only until we have at least one new entry - just a placeholder
			maxHistorySessionsShown: 15, // if null, all history sessions are shown
			historySessionItemLinks: "history" // null - no links, "history" - jumps to the history year of the item, "speaker" - each speaker is a link to its linkedin (if available)
		},
		tickets: {
			showIntroDescription: true,
			showExtraInfo: true,
			singleBuyButton: true,
			showPricesWithoutVAT: false, // show only entered price in "tickets"
			showPricesWithVATAddon: false, // shows pre-calculated price with added VAT as remark below base price
			showPricesWithVAT: true, // show prices in "tickets" but with added VAT percent
			showPlusVATAddOn: false, // shows "+ VAT" text after base price
			showVATRemark: true, // show remark about prices below the Buy button
			showPricesAlt: false, // this is now fixed, EUR is here and going anywhere
			showIcons: false, // if true, and icon is defined, it will be shown after ticket name
			showDates: true, // if true, and dates are defined, the range will be shown after ticket name (and description)
			showDescription: true, // if true, and description is defined, it will be shown below ticket name
			showAccessList: true, // if true, and access list is defined, it will be shown below date range
		},
		historyGallery: {
			splitEvents: false, // if true, each event will start new "line" while listing years for that event
			filterByCurrent: true, // if true, only events for "current" event will be shown (eq. if event is weblica - only weblica past events will be shown)
			showEventNameInTitle: false, // if true, title + year is shown - only makes sense if single event is in gallery (filterByCurrent=true)
			eventColorsOnBoxes: false,
		},
		sponsors: {
			showStarCountNumber: false,
			linkStyle: "popup", // popup - opens a popup with details || direct - directly links to given URL
			showLevelAs: "none", // none || stars || text-color || text-icon
			horizontalLevels: false, // if true, additional class is added to force "row wrap" alt styling
			titleTextIdent: "titleSponsorsAndPartners", // sponsors || sponsorsAndPartners
			titleHandleTextIdent: "titleHandleSponsorsAndPartners", // sponsors || sponsorsAndPartners
			flatten: false, // if true, all sponsors, exhibitors and partners will be is a simple flat grid; if false - everything will be grouped according to type and level
			sorted: false,
			levels: {
				l5: {
					icon: "svgs/sponsor-icon-l5.svg"
				},
				l4: {
					icon: "svgs/sponsor-icon-l4.svg"
				},
				l3: {
					icon: "svgs/sponsor-icon-l3.svg"
				},
				l2: {
					icon: "svgs/sponsor-icon-l2.svg"
				},
				l1: {
					icon: "svgs/sponsor-icon-l1.svg"
				},
			}
		},
		venue: {
			showDescription: true,
			showGoogleMapsLink: true,
			imagesDisplay: "gallery" // separate || gallery
		},
	},
	company: {
		name: "Weblica j.d.o.o.",
		address: "Športska 6a",
		city: "Čakovec",
		zipCode: "40000",
		country: "Croatia",
		countryISO2: "HR",
		email: "info@weblica.hr",
		url: "https://weblica.hr"
	},
	events: {
		weblica: {
			order: 1,
			enabled: true, // if true, event is fully enabled and all sections with data are visible
			lowKey: false, // if true, event is shown as low key (dimmed color/logo on home page and headers)
			visible: true, // if true, event is visible (on home page, build process and so on)
			visibleInHeader: true, // if true, event is visible in header (main "visible" overrides this)
			visibleInHistory: true, // if true, event is visible in footer history (main "visible" overrides this)
			baseUrl: "/weblica",
			ident: "weblica",
			title: "Weblica",
			logoFavIcon: "logo2025-weblica.svg", // "weblica-nt-nb.png",
			logoFull: "logo2025-weblica.svg", //"weblica-wt.svg",
			logoFullLight: "logo2025-weblica.svg",
			logoHeader: "logo2025-weblica.svg", // "weblica-nt.svg",
			logoSelection: "logo2025-weblica.svg", // "weblica-nt-nb.svg",
			baseColor: "#00abf4",
			options: {},
			years: {
				2015: true,
				2016: true,
				2017: true,
				2018: true,
				2019: true,
				2020: false,
				2021: false,
				2022: false,
				2023: false,
				2024: true
			},
			survey: {
				2024: false,
				2025: true
			}
		},
		hacklica: {
			order: 3,
			enabled: true,
			lowKey: false,
			visible: true,
			visibleInHeader: true,
			visibleInHistory: true,
			baseUrl: "/hacklica",
			ident: "hacklica",
			title: "Hacklica",
			logoFavIcon: "logo2025-hacklica.svg", // "hacklica-nt-nb.png",
			logoFull: "logo2025-hacklica.svg", // "hacklica-wt.svg",
			logoFullLight: "logo2025-hacklica.svg", // "hacklica-wb.svg",
			logoHeader: "logo2025-hacklica.svg", // "hacklica-nt.svg",
			logoSelection: "logo2025-hacklica.svg", // "hacklica-nt-nb.svg",
			baseColor: "#c2e225",
			options: {
				teamMinimum: 2,
				teamMaximum: 4,
				maxTeams: 32,
				limitTeamsNumber: true,
			},
			years: {
				2018: true,
				2019: true,
				2020: false,
				2021: false,
				2022: false,
				2023: false,
				2024: false,
			},
			survey: {
			},
			secretPages: [ // pages that match this will not be generated when event is not visible and/or enabled (logic in gatsby-node.js)
				"/hacklica/.+"
			]
		},
		worklica: {
			order: 2,
			enabled: true,
			lowKey: false,
			visible: true,
			visibleInHeader: true,
			visibleInHistory: true,
			baseUrl: "/worklica",
			ident: "worklica",
			title: "Worklica",
			logoFavIcon: "logo2025-worklica.svg", // "worklica-nt-nb.png",
			logoFull: "logo2025-worklica.svg", // "worklica-wt.svg",
			logoFullLight: "logo2025-worklica.svg", // "worklica-wb.svg",
			logoHeader: "logo2025-worklica.svg", // "worklica-nt.svg",
			logoSelection: "logo2025-worklica.svg", // "worklica-nt-nb.svg",
			baseColor: "#7453fb",
			options: {},
			years: {
				2019: true,
				2020: false,
				2021: false,
				2022: false,
				2023: false,
				2024: false,
			},
			survey: {
				2025: true
			},
		},
	},
	press: [
		{
			when: "2019-05-16",
			title: "FOI-jevci osvojili natjecanje u Čakovcu - predstavili rješenje koje koristi umjetnu inteligenciju",
			url: "https://evarazdin.hr/nasim-krajem/foi-jevci-na-natjecanju-u-cakovcu-revolucionarnim-rjesenjem-osvojili-prvo-mjesto-i-18-tisuca-kuna-377765/",
		},
		{
			when: "2019-05-13",
			title: "FOTO: Peta Weblica okupila IT stručnjake iz Međimurja",
			url: "https://radio1.hr/foto-peta-weblica-okupila-it-strucnjake-iz-medimurja/",
		},
		{
			when: "2019-05-11",
			title: "FOTO Čakovec po peti put okupljalište IT stručnjaka - otvorena Weblica",
			where: "emedjimurje.net.hr",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3448313/foto-cakovec-po-peti-put-okupljaliste-it-strucnjaka-otvorena-weblica/",
		},
		{
			when: "2019-05-10",
			title: "Sutra počinje 5. Weblica u Čakovcu!",
			url: "https://www.mnovine.hr/medimurje/sutra-pocinje-5-weblica-u-cakovcu/",
		},
		{
			when: "2018-05-16",
			title: "FOTO Weblica je fenomen koji se rijetko viđa u Međimurju",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3000161/foto-weblica-je-fenomen-koji-se-rijetko-vida-u-medimurju/",
		},
		{
			when: "2018-05-08",
			title: "Ususret Weblici: U Čakovec u subotu stižu najbolji hrvatski IT stručnjaci!",
			url: "https://www.mnovine.hr/hrvatska/aktualno/ususret-weblici-u-cakovec-u-subotu-stizu-najbolji-hrvatski-it-strucnjaci/",
		},
		{
			when: "2018-04-23",
			title: "Hacklica – 24 satni hackathon u sklopu konferencije Weblica 2018.",
			url: "https://www.foi.unizg.hr/hr/novosti/hacklica-24-satni-hackathon-u-sklopu-konferencije-weblica-2018",
		},
		{
			when: "2018-04-22",
			title: "Ponovno okupljanje jake IT scene sa sjevera na 4. izdanju Weblice",
			url: "https://www.mnovine.hr/hrvatska/aktualno/ponovno-okupljanje-jake-it-scene-sa-sjevera-na-4-izdanju-weblice/",
		},
		{
			when: "2017-05-17",
			title: "WEBLICA pozicionira Međimurje kao informatičku regiju",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3028073/weblica-pozicionira-medimurje-kao-informaticku-regiju/",
		},
		{
			when: "2017-05-16",
			title: "Weblica u Čakovcu okupila programere i dizajnere, zanimljivo predavanje Damira Sabola",
			url: "https://www.mnovine.hr/lifestyle/auto-i-techno/weblica-cakovcu-okupila-programere-dizajnere-zanimljivo-predavanje-damira-sabola/",
		},
		{
			when: "2017-05-11",
			title: "Konferencija o web tehnologijama Weblica 2017. ove subote u TICM-u",
			url: "https://medjimurska-zupanija.hr/2017/05/11/konferencija-o-web-tehnologijama-weblica-2017-ove-subote-u-ticm-u/",
		},
		{
			when: "2017-05-11",
			title: "WEBLICA Treće izdanje konferencije otvara računalni genij Damir Sabol",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3028581/weblica-trece-izdanje-konferencije-otvara-racunalni-genij-damir-sabol/",
		},
		{
			when: "2017-04-11",
			title: "WEBLICA 2017 - konferencija o web tehnnologijama",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3031127/weblica-2017-konferencija-o-web-tehnnologijama/",
		},
		{
			when: "2016-05-17",
			title: "Weblica 2016. - skup stručnjaka za umreženi svijet",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3054707/weblica-2016-skup-strucnjaka-za-umrezeni-svijet/",
		},
		{
			when: "2016-04-21",
			title: "WEBLICA 2016 – konferencija o web tehnologijama u „staroj vojarni“",
			where: "zimo.dnevnik.hr",
			url: "https://zimo.dnevnik.hr/clanak/weblica-2016-konferencija-o-web-tehnologijama-u-zstaroj-vojarni---434407.html",
		},
		{
			when: "2016-04-04",
			title: "Tehnološka konferencija Weblica 2016. ponovo u Čakovcu",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3058253/tehnoloska-konferencija-weblica-2016-ponovo-u-cakovcu/",
		},
		{
			when: "2015-05-19",
			title: "Međimurska konferencija Weblica nadmašila sva očekivanja",
			url: "https://zimo.dnevnik.hr/clanak/medjimurska-konferencija-weblica-nadmasila-sva-ocekivanja---400955.html",
		},
		{
			when: "2015-05-13",
			title: "Održana tehnološka konferencija: U IT sektoru nedostaje kadrova",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3080467/tehnoloska-konferencija-u-medimurju-u-it-sektoru-nedostaje-kadrova/",
		},
		{
			when: "2015-04-02",
			title: "WEBLICA - prva prava tehnološka konferencija med Murom i Dravom",
			url: "https://emedjimurje.net.hr/vijesti/gospodarstvo/3083151/weblica-2015-prva-prava-tehnoloska-konferencije-med-murom-i-dravom/",
		},
		{
			when: "2024-04-15",
			title: "Weblica 2024 - Ponovni susret IT entuzijasta u Čakovcu",
			url: "https://www.bug.hr/promo/weblica-2024-ponovni-susret-it-entuzijasta-u-cakovcu-40107",
		},
		{
			when: "2024-04-05",
			title: "Weblica se vraća",
			url: "https://rep.hr/vijesti/edukacija-i-dogadanja/weblica-se-vraca/9630/",
		},
		{
			when: "2024-05-24",
			title: "Održana je Weblica 2024: U Čakovcu se okupilo više od 300 zaljubljenika u IT",
			url: "https://emedjimurje.net.hr/vijesti/drustvo/2951405/foto-odrzana-je-weblica-2024-u-cakovcu-se-okupilo-vise-od-300-zaljubljenika-u-it/"
			// multi: [
			// 	{
			// 	},
			// 	{
			// 	},
			// 	{
			// 	},
			// 	{
			// 	}
			// ]
		},
		{
			when: "2024-05-24",
			title: "Održana je Weblica 2024: U Čakovcu se okupilo više od 300 zaljubljenika u IT",
			url: "https://www.mnovine.hr/medimurje/drustvo/odrzana-je-weblica-2024-u-cakovcu-se-okupilo-vise-od-300-zaljubljenika-u-it/",
		},
		{
			when: "2024-05-24",
			title: "Održana je Weblica 2024: U Čakovcu se okupilo više od 300 zaljubljenika u IT",
			url: "https://muralist.hr/kultura/odrzana-je-weblica-2024-u-cakovcu-se-okupilo-vise-od-300-zaljubljenika-u-it/",
		},
		{
			when: "2024-05-27",
			title: "Održana je Weblica 2024: U Čakovcu se okupilo više od 300 zaljubljenika u IT",
			url: "https://www.bug.hr/dogadjaji/odrzana-je-weblica-2024-u-cakovcu-se-okupilo-vise-od-300-zaljubljenika-u-it-41159",
		},
		{
			when: "2024-05-08",
			title: "Weblica 2024: Regionalna konferencija o web tehnologijama se vraća ovog petka!",
			url: "https://medjimurski.hr/weblica-2024-regionalna-konferencija-o-web-tehnologijama-se-vraca-ovog-petka/"
		},
		{
			when: "2025-04-03",
			title: "Weblica 2025.: Tehnološki susret na međimurskim bregima",
			url: "https://medjimurski.hr/weblica-2025-tehnoloski-susret-na-medimurskim-bregima/"
		}
	],
}
